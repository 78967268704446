import React from "react"
import styled, { createGlobalStyle, keyframes } from "styled-components"
import { Helmet } from "react-helmet"

import Header from "../components/Header"
import Jumbo from "../components/Jumbo"
import DayDate from "../components/DayDate"
import Details from "../components/Details"
import Frieze from "../components/Frieze"

export default function App() {
  return (
    <AppContainer>
      <GlobalStyles />
      <MetaData />
      <Frieze />
      <Header />
      <Jumbo>15</Jumbo>
      <DayDate />
      <Details />
      <Frieze />
    </AppContainer>
  )
}

const MetaData = () => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>Save the Date - chialat!</title>
    <link rel="canonical" href="https://savethedate.chialat.com" />
  </Helmet>
)

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
`

const AppContainer = styled("div")`
  font-family: sans-serif;
  text-align: center;
  font-family: "Oswald", sans-serif;
  color: #ea0101;
  max-width: 800px;
  margin: auto;
  animation: ${fadeIn} 1500ms;
`
const GlobalStyles = createGlobalStyle`
  html {
    font-size: 36px;
    background-color: #fffaf0;
  }

  @media (max-width: 568px) {
    html {
      font-size: 24px;
    }
  }
`
