import styled from "styled-components"

const ContainerBase = styled("div")`
  padding: 1rem 0.25rem;
  border: solid 2px #ea0101;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 568px) {
    font-size: 0.75rem;
  }
  h2,
  p {
    margin: 0px;
  }
`

export default ContainerBase
