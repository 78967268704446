import styled from "styled-components"

const ContainerSide = styled("div")`
  flex: 1;
  text-align: ${p => p.textAlign};
  font-size: ${p => `${p.fontSize || 1.2}rem`};
  min-width: 2rem;
  font-weight: 600;
  @media (max-width: 568px) {
    font-size: ${p => `${(p.fontSize || 1.2) / 1.2}rem`};
  }
`

export default ContainerSide
