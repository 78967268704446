import React from "react"
import styled, { css } from "styled-components"

import ContainerBase from "./ContainerBase"
import ContainerSide from "./ContainerSide"

function DayDate() {
  return (
    <ScoopContainer>
      <Container1>
        <ContainerSide textAlign="left">十五日</ContainerSide>
        <h2>SUNDAY</h2>
        <ContainerSide textAlign="right">星期天</ContainerSide>
      </Container1>
      <Scoop scoop="tL" />
      <Scoop scoop="tR" />
      <Scoop scoop="bR" />
      <Scoop scoop="bL" />
    </ScoopContainer>
  )
}

export default DayDate

const ScoopContainer = styled("div")`
  overflow: hidden;
  position: relative;
  margin-bottom: 0.4rem;
  border-width: 4px;
`
const Container1 = styled(ContainerBase)`
  border: ${getBorder};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`
const Scoop = styled("div")`
  background: ${p => p.theme.backgroundColour};
  border: ${getBorder};
  position: absolute;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  ${p => scoopMap[p.scoop]};
`

const scoopMap = {
  tL: css`
    left: -32px;
    top: -32px;
    @media (max-width: 568px) {
      left: -40px;
      top: -40px;
    }
  `,
  tR: css`
    top: -32px;
    right: -32px;
    @media (max-width: 568px) {
      top: -40px;
      right: -40px;
    }
  `,
  bR: css`
    right: -32px;
    bottom: -32px;
    @media (max-width: 568px) {
      right: -40px;
      bottom: -40px;
    }
  `,
  bL: css`
    left: -32px;
    bottom: -32px;
    @media (max-width: 568px) {
      left: -40px;
      bottom: -40px;
    }
  `,
}

function getBorder(p) {
  return `solid 4px ${p.theme.primaryColour}`
}
