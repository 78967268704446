import React from "react"
import styled from "styled-components"

import ContainerBase from "./ContainerBase"
import ContainerSide from "./ContainerSide"

function Details() {
  return (
    <div>
      <DetailWrapper top>SAVE THE DATE</DetailWrapper>
      <Container2>
        <ContainerSide textAlign="center" fontSize={2}>
          谢
        </ContainerSide>
        <DetailsMiddle>
          <h2>Thomas & Belle</h2>
          <p>Invitation to follow</p>
        </DetailsMiddle>
        <ContainerSide textAlign="center" fontSize={2}>
          囍
        </ContainerSide>
      </Container2>
      <DetailWrapper bottom>SINGAPORE</DetailWrapper>
    </div>
  )
}

export default Details

const DetailWrapper = styled("p")`
  padding: 0.2rem 0;
  margin: 0;
  border: solid 2px;
  border-top: ${p => (p.top ? undefined : "0px")};
  border-bottom: ${p => (p.bottom ? undefined : "0px")};
`
const Container2 = styled(ContainerBase)`
  padding: 0px;
`
const DetailsMiddle = styled("div")`
  border-right: solid 2px;
  border-left: solid 2px;
  padding: 0.5rem;
  p {
    font-size: 0.75rem;
  }
`
