import React from "react"
import styled from "styled-components"

const Frieze = () => {
  const containerRef = React.useRef()
  const { width: windowWidth } = useWindowWidth()
  const { width } = useDimensions(containerRef)
  const size = windowWidth < 568 ? "small" : "normal"
  const count = width ? Math.floor(width / sizeMap[size].width) : 0
  return (
    <Container ref={containerRef}>
      {new Array(count).fill(undefined).map((_, i) => (
        <Single key={i} size={size} />
      ))}
    </Container>
  )
}

const Single = ({ size }) => {
  const strokeWidth = `${sizeMap[size].strokeWidth}px`
  return (
    <SVG size={size}>
      <Path fill="transparent" strokeWidth={strokeWidth} size={size} />
    </SVG>
  )
}

const sizeMap = {
  normal: {
    width: 45,
    height: 35,
    strokeWidth: 5,
  },
  small: {
    width: 17,
    height: 15,
    strokeWidth: 2,
  },
}

export default Frieze

const Container = styled("div")`
  width: 100%;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.2rem 0px;
`
const SVG = styled("svg")`
  width: ${({ size }) => `${sizeMap[size].width}px`};
  height: ${({ size }) => `${sizeMap[size].height}px`};
`
const Path = styled("path").attrs(p => ({
  d: getPathDefinition(p),
}))`
  stroke: ${p => p.theme.primaryColour};
`

function getPathDefinition({ size = "normal" } = {}) {
  if (size === "normal") {
    return "M2.5 35 v -32.5 h 30 v 15 h -15 v 15 h 30"
  } else {
    return "M1 15 v -14 h 12 v 6 h -6 v 7 h 10"
  }
}

function useDimensions(node) {
  const [dimensions, setDimensions] = React.useState({})
  function _setDimensions() {
    setDimensions(node.current.getBoundingClientRect())
  }
  React.useEffect(() => {
    if (node) {
      _setDimensions()
    }
  }, [node, dimensions])
  return dimensions
}

function useWindowWidth() {
  const [dimensions, setDimensions] = React.useState(getDimensions())
  function _setDimensions() {
    setDimensions(getDimensions())
  }
  React.useEffect(() => {
    onlyIfWindow(() => {
      window.addEventListener("resize", _setDimensions)
    })
    return () => {
      onlyIfWindow(() => {
        window.removeEventListener("resize", _setDimensions)
      })
    }
  }, [])
  return dimensions
}
function getDimensions() {
  let width = 0
  let height = 0
  onlyIfWindow(() => {
    width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
  })

  onlyIfWindow(() => {
    height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
  })
  return { height, width }
}

function onlyIfWindow(cb) {
  if (typeof window !== "undefined") {
    cb()
  }
}
