import styled from "styled-components"

const Jumbo = styled("div")`
  font-size: 10rem;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 1rem;
`

export default Jumbo
