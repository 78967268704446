import React from "react"
import styled from "styled-components"

function Header() {
  return (
    <div>
      <HeaderContainer>
        <div>2019</div>
        <div>SEPTEMBER</div>
        <div>九月</div>
      </HeaderContainer>
    </div>
  )
}

export default Header

const HeaderContainer = styled("div")`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0px;
  border-top: solid 8px #ea0101;
  border-bottom: solid 8px #ea0101;
`
